"use client";
import { useAccordion } from "@/components/useAccordion";
import { useRefinementList, RefinementListProps } from "react-instantsearch";
import { Checkbox, Link } from "@cappelendamm/design-system";
import classNames from "classnames";
import { PiMinus, PiPlus } from "react-icons/pi";

export type FacetProps = {
  config: RefinementListProps;
  title: string;
  initialExpanded?: boolean;
};

export function Facet({ config, title, initialExpanded = true }: FacetProps) {
  const {
    items,
    refine,
    searchForItems,
    canToggleShowMore,
    isShowingMore,
    toggleShowMore,
  } = useRefinementList(config);

  const {
    isExpanded,
    aria: { button, panel },
    toggle,
  } = useAccordion(initialExpanded);

  return (
    <fieldset className="block h-fit">
      <div
        className={classNames(
          "grid grid-cols-[1fr_auto] items-center border-b",
          { "pb-4": isExpanded },
        )}
      >
        <legend className={"font-semibold"}>{title}</legend>
        <button
          className="flex h-12 w-12 items-center justify-end"
          onClick={toggle}
          {...button}
        >
          {isExpanded ? <PiMinus /> : <PiPlus />}
          <span className="sr-only">
            {isExpanded ? `Vis ${title}` : `Gjem ${title}`}
          </span>
        </button>
        {isExpanded ? (
          <div
            className={classNames(
              isExpanded ? "grid" : "hidden",
              "col-span-full",
            )}
            {...panel}
          >
            {config.searchable ? (
              <>
                <label
                  htmlFor={`${config.attribute}-search`}
                  className="sr-only"
                >{`Søk ${title}`}</label>
                <input
                  className="cvr-control col-span-full rounded-full px-4"
                  id={`${config.attribute}-search`}
                  type="search"
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck={false}
                  maxLength={512}
                  onChange={(event) =>
                    searchForItems(event.currentTarget.value)
                  }
                />
              </>
            ) : null}
            <ul className="col-span-full">
              {items.map((item) => (
                <li key={item.label}>
                  <label className="grid h-12 grid-cols-[auto_1fr_auto] items-center gap-x-4">
                    <Checkbox
                      checked={item.isRefined}
                      onChange={() => {
                        refine(item.value);
                      }}
                    />
                    <span>{item.label}</span>
                    <span className="text-right">{`${item.count}`}</span>
                  </label>
                </li>
              ))}
            </ul>
            {canToggleShowMore ? (
              <Link
                as="button"
                onClick={toggleShowMore}
                disabled={!canToggleShowMore}
                className={"justify-start"}
              >
                {isShowingMore ? "Vis færre" : "Vis flere"}
              </Link>
            ) : null}
          </div>
        ) : null}
      </div>
    </fieldset>
  );
}
