import Image from "next/image";
import { type ReactNode, type JSX } from "react";
import FallbackImage from "@/components/Rosett.svg";
import classNames from "classnames";
import { Nullable } from "@/types";
import Link from "next/link";

export type ProductItemArticleProps = Nullable<{
  image?: ReactNode;
  imageUrl?: string;
  sku?: string;
  title?: string;
  authors?: (string | null)[];
  price?: ReactNode;
  format?: string;
  language?: string;
  numberInSeries?: string | number;
  seriesName?: string;
  config?: {
    showFormatMeta?: boolean | null;
    showSeries?: boolean | null;
  } | null;
}>;

export const PRODUCT_ARTICLE_IMAGE_WIDTH = 140 as const;
export const PRODUCT_ARTICLE_IMAGE_HEIGHT = 222 as const;

export function ProductItemArticle({
  image,
  imageUrl,
  title,
  price,
  sku,
  authors,
  format,
  language,
  numberInSeries,
  seriesName,
  className,
  children,
  subscriptionHref,
  config = {
    showFormatMeta: true,
    showSeries: true,
  },
  ...article
}: ProductItemArticleProps &
  Omit<JSX.IntrinsicElements["article"], keyof ProductItemArticleProps> & {
    subscriptionHref?: string;
  }) {
  const meta = { format, language };

  return (
    <article
      className={classNames(
        className,
        "list-subgrid-rows group cursor-pointer gap-1",
      )}
      aria-labelledby={`item-${sku}`}
      {...article}
    >
      {children}
      <div className="order-1 flex items-center justify-center rounded-sm bg-gray-100 px-2 py-6 md:px-6">
        {image ??
          (imageUrl ? (
            <Image
              src={imageUrl}
              alt={`Omslag av "${title}"`}
              width={PRODUCT_ARTICLE_IMAGE_WIDTH}
              height={PRODUCT_ARTICLE_IMAGE_HEIGHT}
            />
          ) : (
            <Image
              src={FallbackImage}
              alt={`Manglende bilde - Viser Cappelendamm logo`}
              width={PRODUCT_ARTICLE_IMAGE_WIDTH / 2}
              className="h-full min-h-[222px] opacity-50"
            />
          ))}
      </div>

      <h3
        id={`item-${sku}`}
        className="text-md order-3 font-semibold group-hover:underline"
      >
        {title}
      </h3>
      <p className="text-md order-4 block font-normal text-[#494546]">
        {authors?.filter(Boolean).join(", ")}
      </p>

      {price ? (
        <div className="order-2 text-2xl font-semibold [&_span]:text-left">
          {price}
        </div>
      ) : null}
      {config?.showFormatMeta ? (
        <div className="order-5 mt-2 flex gap-2 text-sm [&_span:not(:first-of-type)]:border-l [&_span:not(:first-of-type)]:pl-2">
          {Object.entries(meta)
            .filter(([_, value]) => Boolean(value))
            .map(([key, value]) => (
              <span key={key}>{value}</span>
            ))}
        </div>
      ) : null}
      {numberInSeries &&
      seriesName &&
      config?.showSeries &&
      subscriptionHref ? (
        <div className="order-6 text-sm">
          {`Bok ${numberInSeries} i `}
          <Link href={subscriptionHref} className="text-[#9A4234] underline">
            {seriesName}
          </Link>
        </div>
      ) : null}
    </article>
  );
}
