"use client";
import { Configure, ConfigureProps } from "react-instantsearch";
import { ResultListProducts } from "./ResultListProducts";
import { useState } from "react";
import { PiArrowLeft, PiFadersHorizontal, PiX } from "react-icons/pi";
import { FacetList } from "./FacetList";
import { NumberOfHits } from "./NumberOfHits";

export type SearchRefinementProps = {
  configure?: ConfigureProps;
  internalTitle?: string;
};

export function SearchRefinement({
  configure,
  internalTitle,
}: SearchRefinementProps) {
  const [state, setState] = useState({ showFilters: false });
  return (
    <>
      <Configure {...configure} />
      <div className="grid grid-cols-2 md:grid-cols-[minmax(25%,auto)_1fr] md:gap-8">
        <button
          onClick={() => setState((prev) => ({ ...prev, showFilters: true }))}
          className="col-span-full flex w-full items-center justify-center gap-x-4 rounded-full border bg-[#9A4234] px-4 py-2 text-white hover:bg-[#5F2920] md:hidden"
        >
          <PiFadersHorizontal />
          Filter
        </button>
        <ResultListProducts
          className="order-last col-span-2 md:col-span-1"
          internalTitle={internalTitle}
        />
        <dialog
          open={state.showFilters ? true : undefined}
          className="fixed top-0 left-0 h-screen w-screen auto-rows-min overflow-y-auto p-4 open:grid md:hidden"
        >
          <button
            onClick={() =>
              setState((prev) => ({ ...prev, showFilters: false }))
            }
            className="flex h-12 w-12 items-center justify-center justify-self-end rounded-full bg-[#F7F7F8]"
          >
            <PiX />
            <span className="sr-only">Vis treff</span>
          </button>
          <h2 className="text-2xl font-semibold">Filter</h2>
          <FacetList
            configure={configure}
            className="grid"
            initialExpanded={false}
          />
          <button
            onClick={() =>
              setState((prev) => ({ ...prev, showFilters: false }))
            }
            className="col-span-full mt-4 flex w-fit items-center justify-center gap-x-4 justify-self-center rounded-full border bg-[#9A4234] px-4 py-2 text-white hover:bg-[#5F2920] md:hidden"
          >
            <PiArrowLeft />
            <NumberOfHits template="Vis {numberOfHits} treff" />
          </button>
        </dialog>
        <FacetList configure={configure} className="hidden md:grid" />
      </div>
    </>
  );
}
