"use client";

import { ProductItemArticle } from "@/products/components/ProductItemArticle";
import { BookHit } from "@cappelendamm/product-search";
import classNames from "classnames";
import Link from "next/link";
import {
  useInfiniteHits,
  UseInfiniteHitsProps,
} from "react-instantsearch-core";
import { getPageLink } from "../../navigation/selectors";
import { Price } from "@/products/components/Price";
import type { JSX } from "react";
import { Track } from "@/tracking";

export type ResultListProductsProps = {
  config?: UseInfiniteHitsProps<BookHit>;
  internalTitle?: string;
};

export function ResultListProducts({
  config,
  internalTitle,
  className,
  ...rest
}: ResultListProductsProps & JSX.IntrinsicElements["div"]) {
  const { items, sendEvent, showMore, isLastPage } =
    useInfiniteHits<BookHit>(config);
  return (
    <div
      className={classNames(className, "grid justify-center gap-24")}
      data-insights-query-id={internalTitle}
      {...rest}
    >
      <ol
        className={classNames(
          "product-list grid-cols-2 gap-x-6 [--list-row-count:7] sm:grid-cols-3 md:grid-cols-4",
        )}
      >
        {items.map((hit) => {
          const {
            objectID: sku,
            imageUrl,
            title,
            price,
            authors,
            format,
            language,
            numberInSeries,
            seriesName,
          } = hit;
          const href = getPageLink({
            pageType: "book",
            params: sku,
          });
          return (
            <li
              key={sku}
              className="list-subgrid-rows mt-6"
              onClick={() => sendEvent("click", hit, "Hit Clicked")}
              onAuxClick={() => sendEvent("click", hit, "Hit Clicked")}
              data-insights-object-id={hit.objectID}
              data-insights-position={hit.__position}
              data-insights-query-id={hit.__queryID}
            >
              <Link href={href} className="list-subgrid-rows">
                <ProductItemArticle
                  imageUrl={imageUrl}
                  title={title}
                  authors={authors}
                  format={format}
                  language={language}
                  numberInSeries={numberInSeries}
                  seriesName={seriesName}
                  sku={sku}
                  price={<Price subTotal={{ gross: Number(price) }} />}
                />
              </Link>
            </li>
          );
        })}
      </ol>
      {!isLastPage ? (
        <button
          className="justify-self-center rounded-full border-2 border-[#9A4234] px-4 py-2 text-[#9A4234] hover:bg-[#5F2920]/10"
          onClick={showMore}
        >
          Last inn flere
        </button>
      ) : null}
      <Track
        event={{
          eventName: "view_item_list",
          ecommerce: {
            item_list_id: internalTitle,
            item_list_name: `${internalTitle}`,
            items: items.map(({ name, sku, price }, index) => ({
              index,
              item_id: `${sku}`,
              item_name: `${name}`,
              item_category: "book",
              item_variant: `${sku}`,
              price: Number(price),
            })),
          },
        }}
        method="on-mount"
      />
    </div>
  );
}
