"use client";

import { useEffect } from "react";
import tracker from "../tracker";
import {
  AddShippingInfoEvent,
  BeginCheckoutEvent,
  PurchaseEvent,
  SearchEvent,
  ViewCartEvent,
  ViewECommerceItemEvent,
  ViewECommerceItemListEvent,
  ViewPromotionEvent,
} from "../e-commerce-events";
import { useTrackIntersectionObserver } from "./useTrackIntersectionObserver";
import { usePathname } from "next/navigation";

type Event =
  | AddShippingInfoEvent
  | BeginCheckoutEvent
  | PurchaseEvent
  | ViewCartEvent
  | ViewECommerceItemEvent
  | ViewECommerceItemListEvent
  | ViewPromotionEvent
  | SearchEvent
  | ({
      eventName: `experiment-success-${string}`;
      experimentPeriod: { start: Date; end: Date };
    } & Record<string, any>);

type TrackingMethod =
  | { method?: "on-mount"; selector?: undefined }
  | {
      method: "in-viewport";
      selector: `#${string}` | `[${string}]` | `.${string}`;
    };

export type TrackProps = {
  event: Event;
} & TrackingMethod;

export function Track({ event, method = "on-mount", selector }: TrackProps) {
  useEffect(() => {
    if (method === "on-mount") tracker.trackEvent(event);
  }, [event, method]);

  const pathname = usePathname();

  useTrackIntersectionObserver(
    pathname,
    event,
    selector,
    method === "in-viewport",
  );

  return null;
}
